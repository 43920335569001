import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import Wolflayout from '../components/wolflayout'
import { components } from '../slices'
import { StaticImage } from 'gatsby-plugin-image'
import { ParallaxProvider, useParallax } from 'react-scroll-parallax'

const ButtonsComponent = () => {
  const { ref: parallaxRef } = useParallax({
    speed: -4
  })
  return (
    <div ref={parallaxRef}>
      <div className="inline-grid grid-cols-3 grid-rows-2 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-1 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
        <a className="landing-page-buttons landing-page-buttons-double bg-pink-wolf2025 row-span-2 col-span-1 px-3 border-4 border-purple-wolf2025" href="/appmobile#info_content">
          <div className="flex flex-col w-full">
            <p className="!w-full">TÉLÉCHARGE<br/>NOTRE APP<br/>POUR LES<br/>NOUVELLES</p>
            <div className="flex flex-col mt-4 w-full">
              <a href="https://apps.apple.com/ca/app/le-festif/id895648726" target="_blank" rel="noreferrer">
                <StaticImage className={'h-[40px]'}
                              src={'../images/stores/appstore-logo-purple.png'}
                              placeholder="blurred"
                              objectFit="contain"
                              alt="Logo App Store"/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=ca.lefestif&hl=fr_CA&gl=US" target="_blank" rel="noreferrer">
                <StaticImage className={'h-[40px] mt-2'}
                              src={'../images/stores/googleplay-logo-purple.png'}
                              placeholder="blurred"
                              objectFit="contain"
                              alt="Logo Google Play Store" />
              </a>
            </div>
          </div>
        </a>
        <a className="landing-page-buttons bg-blue-wolf2025 row-span-1 col-span-1 border-4 border-purple-wolf2025" href="/infos/parvisdufestif#info_content">
          <p className="text-purple-wolf2025">Le Parvis</p>
        </a>
        <a className="landing-page-buttons bg-yellow-wolf2025 row-span-1 col-span-1 border-4 border-purple-wolf2025" href="/infos/scenes#info_content">
          <p className="text-purple-wolf2025">Scènes et sites</p>
        </a>
        <a className="landing-page-buttons bg-yellow-wolf2025 row-span-1 col-span-1 border-4 border-purple-wolf2025" href="/infos/famille#info_content" target='_blank'>
          <p className="text-purple-wolf2025">Prog famille</p>
        </a>
        <a className="landing-page-buttons bg-pink-wolf2025 row-span-1 col-span-1 border-4 border-purple-wolf2025" href="/programmation?filtre=Gratuit#tous">
          <div className="flex flex-col w-full">
            <p className="text-purple-wolf2025">Shows<br/>gratuits</p>
          </div>
        </a>
      </div>
      <center>
        <div className='main-page-project'>
          <div className="flex flex-col md:grid md:grid-cols-3 md:grid-rows-1 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-12 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
            <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/benevolat">
              <StaticImage src="../images/box-devenirbenevole.jpg" alt="Image d'un bénévole" className="w-full h-full object-cover" />
              <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-yellow-wolf2025 text-white !text-left text-xl py-2 flex flex-col">
                <h2>Devenir<br/>bénévole</h2>
                <p>Envie d’apporter ta touche au Festif? Deviens bénévole!</p>
                <div className='w-full !mb-9'>
                  <p className="main-page-project-box-inner-more">Lire les infos</p>
                </div>
              </div>
            </a>
            <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/developpement-durable">
              <StaticImage src="../images/box-developpementdurable.jpg" alt="Image de personnes se serrant dans leurs bras" className="w-full h-full object-cover" />
              <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-pink-wolf2025 text-white text-left text-xl py-2 flex flex-col">
                <h2>Développement<br/>durable</h2>
                <p>Rien de plus cool qu’un festival qui respecte sa planète!</p>
                <div className='w-full'>
                  <p className="main-page-project-box-inner-more">Lire les infos</p>
                </div>
              </div>
            </a>
            <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/infos/services#info_content">
              <StaticImage src="../images/box-zonedesservices.jpg" alt="Image représentant les services offerts par le Festif!" className="w-full h-full object-cover" />
              <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-blue-wolf2025 text-white text-left text-xl py-2 flex flex-col">
                <h2>Zone des<br/>services</h2>
                <p>Pour recharger tes batteries ou celles de ton cell, c’est par ici!</p>
                <div className='w-full'>
                  <p className="main-page-project-box-inner-more">Lire les infos</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </center>
    </div>
  )
}

const TextComponent = () => {
  const { ref: parallaxRef } = useParallax({
    speed: -4
  })
  return (
    <center>
      <div ref={parallaxRef} className="p-[25px] py-18 md:py-4 lg:p-[80px] bg-center">
        <h1 className="uppercase font-obviously font-bold tracking-wider text-white text-4xl mb-12">Le Festif!<br/>de Baie-Saint-Paul</h1>
        <p className="md:!w-3/5 !w-4/5 font-obviously font-medium text-white text-sm lg:text-2xl md:mx-[4rem] xl:mx-[8rem]">Le Festif! de Baie-Saint-Paul est un rassemblement incontournable de la scène musicale québécoise et internationale dont l’engagement local, l’éco-responsabilité, l’audace et la créativité (flirtant avec la magie) sont devenus les marques de commerce.</p>
      </div>
    </center>
  )
}

const ProjetsComponent = () => {
  const { ref: parallaxRef } = useParallax({
    speed: -4
  })
  return (
    <center>
      <div ref={parallaxRef} className='main-page-project pb-9'>
        <h1 className="uppercase text-center font-obviously font-bold tracking-wider text-white text-4xl py-12">Nos autres projets</h1>
        <div className="flex flex-col md:grid md:grid-cols-3 md:grid-rows-1 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] pb-12 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
          <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/cabaret">
            <StaticImage src="../images/projets/main_cabaret.jpg" alt="Image d'un bénévole" className="w-full h-full object-cover" />
            <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-yellow-wolf2025 text-white text-left text-xl pt-2 flex flex-col">
              <h2>Le Cabaret<br/>Festif!</h2>
              <p>Notre volet concours, un tremplin pour les artistes émergent·es de la scène alternative québécoise.</p>
              <div className='w-full'>
                <p className="main-page-project-box-inner-more">Consulter le site</p>
              </div>
            </div>
          </a>
          <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/festif-a-lecole">
            <StaticImage src="../images/projets/main_festif_a_lecole.jpg" alt="Image du Festif! à l'école" className="w-full h-full object-cover" />
            <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-pink-wolf2025 text-white text-left text-xl py-2 flex flex-col">
              <h2>Le Festif!<br/>à l'école</h2>
              <p>Notre volet philanthropique, pour sensibiliser les jeunes à la culture, un spectacle à la fois.</p>
              <div className='w-full'>
                <p className="main-page-project-box-inner-more">Consulter le site</p>
              </div>
            </div>
          </a>
          <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/location">
            <StaticImage src="../images/projets/main_festif_a_louer.jpg" alt="Image du Festif! à louer" className="w-full h-full object-cover" />
            <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-blue-wolf2025 text-white text-left text-xl py-2 flex flex-col">
              <h2>Le Festif!<br/>à louer</h2>
              <p>Besoin de matériel pour ton événement? On peut sûrement te louer le nôtre!</p>
              <div className='w-full'>
                <p className="main-page-project-box-inner-more">Consulter le site</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </center>
  )
}

const IndexPage = ({ data, location }) => {
  if (!data) return null
  const doc = data.prismicHomepage.data
  const metaTitle = data.prismicHomepage.data.metatitle.text
  const metaDescription = data.prismicHomepage.data.metadescription.text
  const metaKeywords = data.prismicHomepage.data.metakeywords.text
  const ogImage = data.prismicHomepage.data.og_img.url

  return (
      <Wolflayout mobileTitle={'Le Festif!'} displayFooterImage={false}>
        <FestifHelmet title={metaTitle}
          description={metaDescription}
          keywords={metaKeywords}
          imageUrl={ogImage}
          href={location.href} />

        <div className='bg-gradient-to-b from-purple-wolf2025 to-pink-wolf2025'>

          {/* Top image / video */}
          <div className="justify-center mx-auto pt-4">
            <center>
              <div className="top-image text-center px-8 md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
                <a href="/programmation">
                  <StaticImage alt="Annonce du dévoilement de la programmation pour le 7 avril et pour l'ouverture de la billetterie le 10 avril." className="max-w-[1920px] max-h-[1080px] transition-transform duration-300 transform main-banner-hover-scale" src="../images/main-page-top-image.png" placeholder="none"/>
                </a>
                {/* <video muted autoPlay loop playsInline>
                  <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdmVEMTzAJOCiie_LeFestif_Prog2024.mov" type="video/mp4" />
                </video> */}
              </div>
            </center>
          </div>

          <div className="uppercase text-center font-obviously font-bold tracking-wider w-2/5 mx-auto pt-0 relative !max-w-[700px] !max-h-[284px] mb-16 hidden md:block">
            <div className="relative">
              <StaticImage src="../images/wolf/ufo.png" alt="Dession d'un UFO" className="w-full" />
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-center md:w-4/5 w-full">
                <p className="text-yellow-wolf2025 text-4xl">Le Festif!</p>
                <p className="text-pink-wolf2025 text-4xl">de Baie-St-Paul</p>
                <p className="text-blue-wolf2025 text-xl">• 17>20 juillet 2025 •</p>
              </div>
            </div>
          </div>

          <div className='stars text-center md:mt-0 mt-9'>

            {/* BUTTONS */}
            <ParallaxProvider>
              <ButtonsComponent />
            </ParallaxProvider>

            {/* AFFICHE */}
            <center>
              <div className="text-center py-24 md:px-48 px-4">
                <StaticImage alt='Affiche artistes Le Festif!' className="mx-auto align-center" src="../images/wolf/affiche.png" imgClassName='!max-w-[1536px] !max-h-[1920px]' />
              </div>
            </center>
            
            {/* SKYLINE */}
            {/* -2px because of a visual glitch on Google Chrome */}
            <div className='!mb-[-2px]'>
              <StaticImage src="../images/wolf/affiche-bottom.png"
                            className='w-full'
                            placeholder="blurred"
                            objectFit="fill"
                            alt="Visuel floral" />
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gradient-to-b from-purple-wolf2025 to-pink-wolf2025 my-0 pt-0">

            {/* TEXTE */}
            <ParallaxProvider>
              <TextComponent />
            </ParallaxProvider>

            {/* PROJECTS */}
            <ParallaxProvider>
              <ProjetsComponent />
            </ParallaxProvider>

          </div>
        </div>

        {/* PARTENAIRES */}
        <SliceZone slices={doc.body} components={components} />
      </Wolflayout>
  )
}

export const query = graphql`
  query {
    prismicHomepage {
      data {
        metadescription {
          text
        }
        metakeywords {
          text
        }
        metatitle {
          text
        }
        og_img {
          url
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodySponsors
          ...HomepageDataBodySponsorsHeader
        }
      }
    }
  }
`

export default IndexPage
